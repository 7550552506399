.vehicleRoute {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: left;
  gap: 10px;
  padding: 20px;
}

.routeButtonsHidden {
  display: flex;
  flex-direction: row;
  visibility: hidden;
}

.routeButtonsVisible {
  display: flex;
  flex-direction: row;
  visibility: visible;
}

.point {
  color: white;
  font-size: 12px;
  display: flex;
  background-color: #1976d2;
  border-radius: 50%;
  min-width: 25px;
  height: 25px;
  padding: 2px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
}

.iconPoint {
  display: flex;
  flex-direction: column;
  position: relative;
}

.additionalIcons {
  position: absolute;
  top: -25px;
  left: 15px;
  display: flex;
}

.vehicleInfo {
  display: flex;
  flex-direction: column;
  min-width: 110px;
}

.car {
  font-size: 25;
}

.carBack {
  font-size: 25;
  transform: scale(-1, 1);
}

.vehicleRouteContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.headerRoute {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}