.UsersInfoPanel {
    display: flex;
    flex-wrap: wrap;
    height: max-content;
    gap: 30px;
    margin: 30px 0 20px;
}

.buttonsPanel{
    display: flex;
    gap: 30px;
}
