.companyTariffPlanPanel {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tariffControl {
  display: flex;
  gap: 20px;
}

.selectedTariff {
  display: flex;
  flex-direction: column;
  gap: 10px;
}