.geoMap {
  height: calc(100vh - 250px);
}

.mainBtns {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loaderMap{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #e0e0e0;
  z-index: 99999;
}