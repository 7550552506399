.tooltip {
  position: absolute;
  display: inline-block;
  height: auto;
  width: auto;
  z-index: 100;
  background-color: #555;
  color: #fff;
  font-size: 14px;
  text-align: left;
  border-radius: 4px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  left: 50%;
  opacity: 0.9;
  transform: translateX(3%);
  visibility: hidden;
  pointer-events: none;
}
