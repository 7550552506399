.rc-tree {
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5,
}

.rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper {
  padding-left: 4px;
  padding-right: 4px;
  /* width: 100%; */
}

.rc-tree-node-content-wrapper:hover {
  background-color: rgba(245, 245, 245);
}

.rc-tree-node-selected {
  background-color: rgba(25, 118, 210, 0.2);
  box-shadow: none;
  opacity: 1;
}

.rc-tree-node-selected:hover {
  background-color: rgba(25, 118, 210, 0.2);
}

.rc-tree .rc-tree-treenode span.rc-tree-switcher, 
.rc-tree .rc-tree-treenode span.rc-tree-checkbox, 
.rc-tree .rc-tree-treenode span.rc-tree-iconEle {
  margin-right: 3px;
}