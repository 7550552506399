.horizontalBox {
  display: flex;
  gap: 20px;
  width: 100%;
}

.card {
  width: 100%;
  height: 400px;
  padding: 20px;
}

.body {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 20px;
}