.panel {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.listPanel {
  display: flex;
  flex-direction: column;
  gap: 10px;
}