.geoMapWithTiles {
  position: relative;
}

.geoMapButtons {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 200;
}

.geoMapButtonMaxContent {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 200;
}


.geoMapWithTilesMax{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}

.geoMapMaxContentMap{
  width: 100%;
  height: 100%;
}
