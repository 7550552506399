.datePeriodInput {
  display: flex;
  gap: 20px;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  gap: 20px
}

.proposal {
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 20px
}

.inputsRouteRole {
  display: flex;
  width: 100%;
  gap: 60px;
}