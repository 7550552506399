.maneuverButtons {
  display: flex;
  gap: 20px;
}

.nextManeuver {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maneuverInfo {
  display: flex;
  justify-content: space-between;
}

.customManeuver {
  display: flex;
  gap: 20px;
  align-items: center;
}

.vehicleManeuverWidget {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}

.planManeuver {
  display: flex;
  align-items: center;
  gap: 10px;
}

.intervalInputs {
  display: flex;
  align-items: center;
  gap: 20px;
}