.buttons {
  display: flex;
  gap: 8px;
  align-items: center;
  min-width: 0;
  justify-content: flex-end;
  flex-shrink: 2;
}
.buttons button {
  min-width: 0;
}

.userBtn {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
  max-width: 80%;
  :global(.MuiButton-startIcon) {
    margin: 0;
  }
}
.userBtnTxt {
  width: 100%;
  display: flex;
  gap: 0 8px;
  line-height: 1.25rem;
}
.userName {
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  z-index: 100;
}

.headerMain {
  flex-grow: 1;
}

.headerResponsive {
  display: flex;
  flex-grow: 1;
}

.headerText {
  display: flex;
  gap: 0 10px;
  align-items: baseline;
  flex-wrap: wrap;
  white-space: nowrap;
}

.titleText {
  text-transform: uppercase;
}
