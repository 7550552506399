.listBox {
  display: flex;
  gap: 40px;
  /* flex-wrap: wrap; */
  width: 100%;
  min-height: 300px;
}

.list {
  min-width: 250px;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 20px;
}