.groupVehiclesContainer {
  flex: 1;
  flex-wrap: nowrap;
  display: flex;
  flex-shrink: 0;
}

.groupVehicles {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
}

.groupVehiclesLong {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  flex-shrink: 0;
}

.carBack {
  font-size: 25;
  transform: scale(-1, 1);
}

.platoonGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.platoonGroupCard {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  gap: 20px;
}

.finishVehicles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-shrink: 0;
}


.groupPointsContainer {
  width: 100%;
  position: relative;
  flex-shrink: 0;
}

.points {
  z-index: 20000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap;
}

.points:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 96px;
  background: white;
  z-index: 10;
}

.points:after {
  content: ' ';
  position: relative;
  height: 96px;
  background: white;
  z-index: 10;
  flex-grow: 1;
}

.point {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
  flex-shrink: 0;
  position: relative;
  z-index: 20;
  max-height: 96px;
}

.point:last-child:after {
  content: ' ';
  position: absolute;
  top: 0;
  right: -10px;
  width: 55px;
  height: 96px;
  background: white;
  z-index: -10;
}

.expired {
  opacity: 0.5;
}

.alarm {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.alarmBadge {
  width: 18px;
  height: 18px;
  position: absolute;
  z-index: 10;
  top: 33%;
  left: 33%;
  margin-left: -9px;
  margin-top: -9px;
  padding: 1px;
  border-radius: 10px;
}

.labelTop {
  display: flex;
  align-items: flex-end;
  height: 35px;
  padding-bottom: 5px;
}

.labelBottom {
  display: flex;
  align-items: flex-start;
  height: 35px;
  padding-top: 5px;
}

.iconPoint {
  font-size: 25;
}

.headerGroup {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.buttonsHidden {
  display: flex;
  flex-direction: row;
  visibility: hidden;
}

.buttonsVisible {
  display: flex;
  flex-direction: row;
  visibility: visible;
}