.UsersInfoPanel {}

.search{
    display: flex;
    gap: 30px;
    margin: 30px 0 20px;
}

.groupButton{
    display: flex;
    gap: 30px;
    width: max-content;
    margin-left: auto;
}

.loaderWrapper{
    display: flex;
    align-content: center;
    justify-content: center;
    padding-top: 80px;
}