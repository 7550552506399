.carImg {
  max-width: 45px;
}

.carIcon {
  display: flex;
  width: 55px;
  background-color: white;
  justify-content: center;
  z-index: 200;
}