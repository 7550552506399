.FiltersRoutesWidget {
  width: 100%;
  padding: 10px;
  padding-top: 20px;
}

.collapseItem {
  width: 100%;
}

.filterButtons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  align-items: flex-end;
}