.widget {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}

.footerControls {
  display: flex;
  justify-content: right;
  gap: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.formHorizontal {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 40px;
}

.dateTime {
  display: flex;
  gap: 20px;
}