.divisionFormWidget {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}

.buttonts {
  display: flex;
  justify-content: right;
  gap: 20px;
}