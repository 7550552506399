.buttons {
  width: 100%;
  display: flex;
  /* padding: 10px; */
  gap: 20px
}

.movementInfo {
  display: flex;
  justify-content: space-between;
}

.nextManeuver {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vehicleMovementWidget {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.maneuverButtons {
  display: flex;
  gap: 20px;
}

.customManeuver {
  display: flex;
  gap: 20px;
  align-items: center;
}