.EquipmentPanel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.EquipmentBlock {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative
}

.AccordionCurrent{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Details{
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  gap: 20px;
}

.AccordionHeader{
  display: flex;
  align-items: center;
  gap: 40px;
  flex-grow: 1;
}


.AccordionGroupButtons{
  display: flex;
  gap: 30px;
  width: max-content;
  margin-left: auto;
  margin-top: 16px;
}
