.geoMap {
  height: calc(100vh - 360px);
  max-height: 600px;
}

.geoMapFull {
  height: calc(100vh - 260px);
  max-height: 600px;
}

.form {
  display: flex;
  flex-direction: column;
  position: sticky;
  max-height: 600px;
  top: 10px;
  gap: 10px;
  width: 100%;
  max-width: 400px;
  min-width: 240px;
}

.formFull {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 10px;
  gap: 10px;
  width: 100%;
  max-width: none;
  min-width: 240px;
  .trackToggler {
    top: -50px;
  }
}
