.UsersInfoPanel {
    display: flex;
    flex-wrap: wrap;
    height: max-content;
    gap: 30px;
    width: 400px;
}

.buttonsPanel{
    display: flex;
    gap: 30px;
}
