.geoZonesToolbarWidget {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.nameGeoZone {
  display: flex;
  gap: 10px;
  justify-content: left;
  align-items: center;
  width: 100%;
}

.controlGeoZoneButtons {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
