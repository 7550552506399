.geoMap {
  height: calc(100vh - 200px);
}

.vehicleMapPanel{
  display: flex;
}
.mapBlock {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8080804f;
}