.controls {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.filters {
  display: flex;
  gap: 10px;
  width: 100%;
}

.toolbar {
  display: flex;
  justify-content:space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.searchText {
  width: 500px;
}