.pointForm {
  display: flex;
  width: 100%;
  gap: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  flex-wrap: wrap;
}
.coordinates{
display: flex;
  gap: 30px;
}


.geoInput {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.checkboxGroup {
  display: flex;
  justify-content: right;
  min-width: 84px;
}

.inputsGroup {
  width: 100%;
  display: flex;
  gap: 20px;
}

.inputsPoint {
  display: flex; 
  width: 100%;
  flex-direction: column;
  gap: 20px;
  padding-top: 10px; 
  padding-bottom: 20px;
}

.collapseItem {
  width: 100%;
}

.pointFormContent {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
}

.pointFormContentForMap {
  display: flex;
  flex-direction: column;
  max-width: 806px;
  width: 100%;
  flex-wrap: wrap;
}

.mapContainer{
  padding-top: 1rem;
}

.geoMap {
  max-width: 806px;
  height: 390px;
}

.informIcon {
  transform: scale(1,-1);
}
.mainContainer{
  width: 100%;
}
.areaMapGroupButtons{
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}
.wrapperInputField{
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 20px;
}
.textFieldCoords{
  width: 100%;
}

.commnet {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  min-height: 48px;
  flex-direction: column;
}