.nameForm{
  max-width: 600px;
  margin: 0;
  padding-bottom: 30px;
}

.companyLogisticsPanel{
  padding-top: 15px;
  width: 100%;
}

.formContainer{
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
}

.formItemFile{
  display: flex;
  gap: 15px;
  align-items: center;
  padding-left: 5px;
  width: 100%;
}

.formItem {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
