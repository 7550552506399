.AlertsPanel {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 400px;
}

.buttonsPanel {
  display: flex;
  gap: 30px;
}
