.AccountMainPanel {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 300px;
}


.buttonsPanel{
  display: flex;
  gap: 30px;
}
