.address {
  align-items: center;
  gap: 6px;
  position: relative;
  .warning {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 0.7em;
    height: 0.7em;
  }
  .addressText {
    cursor: text;
    width: 100%;
    min-width: 100px;
    font-size: 0.875rem;
    line-height: 1.43;
    min-height: 29px;
    padding-top: 4.25px;
    padding-bottom: 4.25px;
  }
}
.mark {
  min-width: 15px;
  height: 15px;
  text-align: center;
  font-size: 10px;
  line-height: 15px;
  border-radius: 8px;
  color: white;
}
