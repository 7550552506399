.routeActivateControl {
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dateTimePeriod {
  display: flex;
  gap: 20px;
  align-items: center;
}

.buttonsControl {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}