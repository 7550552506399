.companyGeoZonesPanel {
  padding-top: 20px;
  padding-bottom: 20px;
}

.geoZonesList {
  /* min-width: 360px; */
  overflow: auto;
  max-height: calc(100vh - 345px);
}

.geoZonesFoundList {
  overflow: auto;
  max-height: calc(100vh - 385px);
}

.formButtons {
  padding-top: 10px;
  display: flex;
  justify-content: right;
  gap: 20px;
}