.companyPaymentsPanel {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
}

.constrols {
    display: flex;
    gap: 20px;
}

.cellTable {
    border-right: 1px solid #e0e0e0;
    width: 100px;
    padding: 0 10px 0 0  !important;
    margin: 0 !important;
}

.cellBox {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.tableHead {
    background-color: #eeeeee;
}

.cellTableHead {
    border-right: 1px solid #d6d6d6;
    width: 100px;
    cursor: pointer;
    padding: 0 !important;
    margin: 0 !important;
}

.payments {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.table {
    min-width: 500px;
    table-layout: fixed;
}