.accelerationIco {
  font-size: 24px;
  rotate: -90deg;
  color: #0B8108;
  margin-left: -12px;
  margin-right: -12px;
}
.decelerationIco {
  font-size: 24px;
  rotate: -90deg;
  margin-left: -12px;
  margin-right: -12px;
}