.vehicleFuelEfficiencyPanel {}

.wrapperAlerts{
    margin-top: 30px;
}

.wrapperTittleAlert{
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.wrapperAlert .MuiAlert-message{
    width: 100% !important;
}

.closeIconAlert{
    cursor: pointer;
}