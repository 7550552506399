.dateTimePeriod {
  display: flex;
  gap: 15px;
  align-items: center;
}

.filterButtons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}

.tariffPlansFiltersWidget {
  width: 100%;
  padding: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.filters {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.dates {
  display: flex;
  gap: 30px;
}