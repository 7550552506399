.UsersInfoPanel {
    display: flex;
    flex-wrap: wrap;
    height: max-content;
    margin: 30px 0 20px;
    flex-direction: column;
}

.buttonsPanel{
    display: flex;
    gap: 30px;
    justify-content: flex-end;
}
