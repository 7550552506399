.vehicleRouteFormWidget {
  display: flex;
  gap: 20px;

  padding: 2px;
  padding-bottom: 40px;
  width: 100%;
  flex-direction: column;
}

.buttons {
  display: flex; 
  justify-content: right;
}

.uploadBtn {
  top: 16px;
  right: 8px;
} 

.points {
  padding-top: 20px;
  display: flex;
  width: 100%;
  justify-content: right;
}

.pointsList {
  padding-bottom: 0;
  max-width: 930px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.passengers {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.colntrol {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.colntrolButtons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
}

.controls {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.tag {
  margin-right: 0;
}

.accordions {
  width: 100%;
  background-color: rgb(240, 240, 240);
}

.addText {
  width: 100%;
  max-width: 450px;
}

.footerControls {
  display: flex;
  justify-content: right;
  gap: 20px;
}

.geoMap {
  height: calc(100vh - 200px);
}