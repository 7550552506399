.form {
  display: flex;
  gap: 30px;
  width: 100%;
  flex-direction: column;
}

.group{
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
  /* padding-left: 10px; */
}

.horizontalBox{
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
}

.verticalBox {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.boxTitle {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
