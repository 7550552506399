.vehicleInput {
  width: 100%;
  max-width: 230px;
  display: flex;
  justify-content: left;
  gap: 5px;
}

.datePeriodInput {
  display: flex;
  gap: 20px;
}

.routeForm {
  padding-top: 10px;
  width: 100%;
  display: flex;
  gap: 30px;
}

.routeInputs {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.routeCheckbox {
  display: flex;
  flex-direction: column;
}